import ArticlePage from '@components/ArticlePage'
import { graphql } from 'gatsby'

export const query = graphql`
  query {
    articleTypes: allContentfulPressPageTypes {
      edges {
        node {
          title
          ignoreTopFeatured
          topFeatured {
            ...PostData
          }
          types {
            title
            slug
          }
        }
      }
    }
    articles: allContentfulNewInnovationsArticle {
      edges {
        node {
          slug
          title
          publishDate(formatString: "MMM D, YYYY")
          types {
            title
            slug
          }
          content {
            json
          }
          excerpt: content {
            json
          }
          image {
            description
            file {
              url
            }
          }
        }
      }
    }
  }
`

export default ArticlePage

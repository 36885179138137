import React from 'react'
import PropTypes from 'prop-types'
import CTA from '@components/CTA'
import Text from '@components/Text'
import Picture from '@components/Picture'
import GradientText from '@components/GradientText'

import classnames from 'classnames/bind'

import styles from './InnovationsArticle.module.scss'
import {
  gradientColor1,
  gradientColor2,
  gradientColor3,
  gradientStop1,
  gradientStop2,
  gradientStop3,
} from '@styles/variables.module.scss'

const cx = classnames.bind(styles)

const InnovationsArticle = ({ date, types, image, title, richText, richExcerpt, url, vimeoUrl, featured, className }) => (
  <div className={featured ? '' : cx('card-gap')}>
    <div className={cx('container', { 'container-featured': featured }, { 'container-card': !featured }, className)}>
      <div className={cx('logo-con', { 'feat-logo-con': featured })}>
        {image && image.file.url.includes('.mp4') ? (
          <video className={cx('partner-logo', { 'feat-logo': featured })} controls>
            Your browser does not support the &lt;video&gt; tag.
            <source src={image.file.url} />
          </video>
        ) : vimeoUrl ? (
          <iframe
            src={vimeoUrl}
            width={featured ? '3100' : '310'}
            height={featured ? '400' : '200'}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        ) : (
          <Picture
            className={cx('partner-logo', { 'feat-logo': featured })}
            src={image ? image.file.url : '/images/ms/g1001-light-gray.png'}
            alt={image ? image.description : 'Group 1001'}
          />
        )}
      </div>

      {!featured && title != 'Media Inquiries' ? (
        <div className={cx('article-info')}>
          <Text tag="p" type="b2Medium" className={cx('date')}>
            {date}
          </Text>
          {types &&
            types.map(
              (type, index) =>
                type.title && (
                  <Text className={cx('type')} tag="p" type="b2" key={index}>
                    {type.title}
                  </Text>
                )
            )}
        </div>
      ) : (
        ''
      )}
      <div className={cx('vert-flex', { 'feat-vert-pad': featured })}>
        {featured && title != 'Media Inquiries' ? (
          <div className={cx('article-info')}>
            <Text tag="p" type="b2Medium" className={cx('date')}>
              {date}
            </Text>
            {types &&
              types.map(
                (type, index) =>
                  type.title && (
                    <Text className={cx('type')} tag="p" type="b2" key={index}>
                      {type.title}
                    </Text>
                  )
              )}
          </div>
        ) : (
          ''
        )}
        <CTA type="quaternary" className="" to={url}>
          {!featured ? (
            <GradientText
              className={cx('title')}
              tag="h4"
              type="h4"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
            >
              {title}
            </GradientText>
          ) : (
            // <Text className={cx('title')} tag="h1" type="h1">
            //   {title}
            // </Text>
            <GradientText
              className={cx('title-featured')}
              tag="h1"
              type="h1"
              colors={[gradientColor1, gradientColor2, gradientColor3]}
              colorStops={[gradientStop1, gradientStop2, gradientStop3]}
            >
              {title}
            </GradientText>
          )}
        </CTA>

        {richExcerpt && <div className={cx('content')} dangerouslySetInnerHTML={{ __html: richExcerpt }} />}

        {richText && <div className={cx('content')} dangerouslySetInnerHTML={{ __html: richText }} />}
      </div>
    </div>
  </div>
)
// const InnovationsArticle = ({ date, types, image, title, richText, richExcerpt, cta, url, featured, className }) => (
//   <div className={cx('container', { 'container-featured': featured }, className)}>
//     <div className={cx('article-info')}>
//       <Text tag="p" type="b2Medium" className={cx('date')}>
//         {date}
//       </Text>
//       {types &&
//         types.map(
//           (type, index) =>
//             type.title && (
//               <Text className={cx('type')} tag="p" type="b2" key={index}>
//                 {type.title}
//               </Text>
//             )
//         )}
//     </div>
//     <CTA type="quaternary" className="" to={url}>
//       {featured ? (
//         <GradientText
//           className={cx('title')}
//           tag="h2"
//           type="h2Bold"
//           colors={[gradientColor1, gradientColor2, gradientColor3]}
//           colorStops={[gradientStop1, gradientStop2, gradientStop3]}
//         >
//           {title}
//         </GradientText>
//       ) : (
//         <Text className={cx('title')} tag="h3" type="h3">
//           {title}
//         </Text>
//       )}
//     </CTA>

//     <Picture className={cx('partner-logo')} src={image.file.url} alt={image.description} />

//     {richExcerpt && <div className={cx('content')} dangerouslySetInnerHTML={{ __html: richExcerpt }} />}

//     {richText && <div className={cx('content')} dangerouslySetInnerHTML={{ __html: richText }} />}

//     {cta && (
//       <CTA className={cx('cta')} type="primary" to={cta.url} causeTransition={true}>
//         {cta.label}
//       </CTA>
//     )}
//   </div>
// )

InnovationsArticle.propTypes = {
  date: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  title: PropTypes.string.isRequired,
  featured: PropTypes.bool,
  richText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  richExcerpt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  cta: PropTypes.shape({
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
}

export default InnovationsArticle
